<template>
    <thead>
        <tr class="rt__row">
            <th class="rt__cell rt__cell--white rt__cell--number rt__cell--sticky" scope="col"></th>
            <th
                class="rt__cell rt__cell--white rt__cell--message rt__cell--sticky"
                scope="col"
            ></th>
            <th class="rt__cell rt__cell--white rt__cell--type rt__cell--sticky" scope="col"></th>
            <th
                :colspan="`${collapsed ? 1 : 3}`"
                class="rt__cell rt__cell--value rt__cell--dark rt__cell--sticky"
                :style="`width: ${collapsed ? 100 : 300}px;`"
                scope="col"
            >
                Total users
            </th>
            <th
                v-for="(reportBreak, idx) in breaks"
                :key="`break-${idx}`"
                class="rt__cell rt__cell--dark"
                :style="`width: ${100 * reportBreak.answers.length}px;`"
                :colspan="reportBreak.answers.length"
                scope="col"
            >
                {{ reportBreak.title }}
            </th>
            <th class="rt__cell rt__cell--dark" style="width: 100px" scope="col">
                <IconButton
                    v-tooltip="`Add Break`"
                    icon="plus-2"
                    class="rt__break-btn"
                    @click="$emit('addBreak')"
                />
            </th>
        </tr>

        <tr class="rt__row">
            <th class="rt__cell rt__cell--number rt__cell--dark rt__cell--sticky" scope="col"></th>
            <th class="rt__cell rt__cell--message rt__cell--dark rt__cell--sticky" scope="col">
                Messages / Answers
            </th>
            <th class="rt__cell rt__cell--type rt__cell--dark rt__cell--sticky" scope="col">
                Msg Type
            </th>
            <th class="rt__cell rt__cell--value rt__cell--light rt__cell--sticky" scope="col">
                <span class="rt__collapse">
                    All
                    <IconButton
                        v-tooltip="`${collapsed ? 'Show All' : 'Collapse'}`"
                        :icon="`${collapsed ? 'expand-toggle' : 'collapse-toggle'}`"
                        class="rt__collapse-btn"
                        @click="$emit('toggleTotalUsers')"
                    />
                </span>
            </th>
            <th
                v-if="!collapsed"
                class="rt__cell rt__cell--light rt__cell--sticky"
                style="left: 490px"
                scope="col"
            >
                Web
            </th>
            <th
                v-if="!collapsed"
                class="rt__cell rt__cell--light rt__cell--sticky"
                style="left: 590px"
                scope="col"
            >
                App
            </th>
            <template v-for="(reportBreak, breakIdx) in breaks">
                <th
                    v-for="(answer, answerIdx) in reportBreak.answers"
                    :key="`answer-${breakIdx}-${answerIdx}`"
                    class="rt__cell rt__cell--light"
                    scope="col"
                >
                    {{ answer.title }}
                </th>
            </template>

            <th class="rt__cell rt__cell--light" scope="col"></th>
        </tr>
    </thead>
</template>

<script>
import IconButton from '@/components/ui/IconButton';

export default {
    name: 'ReportsTableHeader',
    components: {
        IconButton,
    },
    props: {
        breaks: {
            type: Array,
            required: true,
        },
        collapsed: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
.rt__row {
    height: 40px;
}

.rt__cell {
    vertical-align: middle;
    text-align: center;
    text-transform: uppercase;

    &--number {
        width: 40px;
        left: 0px;
    }

    &--white {
        background-color: #fafbfc;
    }

    &--message {
        width: 200px;
        left: 40px;
    }

    &--type {
        width: 150px;
        left: 240px;
    }

    &--value {
        left: 390px;
    }

    &--dark {
        background-color: #f3f4f7;
    }

    &--light {
        background-color: #f9f9fb;
        color: $table-accent-color;
    }

    &--sticky {
        position: sticky;
    }
}

.rt__break-btn {
    width: 22px;
    height: 22px;
    margin: 0 auto;
    cursor: pointer;
}

.rt__collapse {
    display: inline-flex;
    align-items: center;
    &-btn {
        width: 12px;
        height: 12px;
        margin-left: 5px;
        cursor: pointer;
    }
}
</style>
