import { render, staticRenderFns } from "./ReportsTableBody.vue?vue&type=template&id=7f98cb1e&scoped=true&"
import script from "./ReportsTableBody.vue?vue&type=script&lang=js&"
export * from "./ReportsTableBody.vue?vue&type=script&lang=js&"
import style0 from "./ReportsTableBody.vue?vue&type=style&index=0&id=7f98cb1e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f98cb1e",
  null
  
)

export default component.exports