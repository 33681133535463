<template>
    <tr
        class="rt__row"
        :class="{
            'rt__row--question': !!row.type === true,
            'rt__row--response': !!row.type === false,
        }"
    >
        <td class="rt__cell rt__cell--number rt__cell--sticky">{{ row.questionIndex }}</td>

        <td
            v-if="!row.type && row.title === 'VIEW RESPONSES'"
            class="rt__cell rt__cell--message rt__cell--sticky"
        >
            <router-link :to="`report/${row.id}`">
                {{ row.title }}
            </router-link>
        </td>

        <td v-else class="rt__cell rt__cell--message rt__cell--sticky" v-html="row.title"></td>

        <td class="rt__cell rt__cell--type rt__cell--sticky">
            <Badge v-if="row.type" type="neutral">{{ row.type }}</Badge>
        </td>

        <td class="rt__cell rt__cell--value rt__cell--sticky" style="left: 390px">
            <span>
                {{ row.value.total }}
                <Icon v-if="!!row.type === true" class="users-icon" name="users" />
            </span>
        </td>

        <td v-if="!collapsed" class="rt__cell rt__cell--value rt__cell--sticky" style="left: 490px">
            <span>
                {{ row.value.web }}
                <Icon v-if="!!row.type === true" class="users-icon" name="users" />
            </span>
        </td>

        <td v-if="!collapsed" class="rt__cell rt__cell--value rt__cell--sticky" style="left: 590px">
            <span>
                {{ row.value.app }}
                <Icon v-if="!!row.type === true" class="users-icon" name="users" />
            </span>
        </td>

        <template v-for="(reportBreak, breakIdx) in breaks">
            <td
                v-for="(answer, answerIdx) in reportBreak.answers"
                :key="`answer-${breakIdx}-${answerIdx}`"
                class="rt__cell"
            >
                <template v-if="!row.type">
                    {{ getCellValue(breakIdx, row.contentItemId, row.id, answer.id) }}
                </template>
            </td>
        </template>

        <td class="rt__cell"></td>
    </tr>
</template>

<script>
import Badge from '@/components/ui/Badge';
import Icon from '@/components/ui/Icon';

export default {
    name: 'ReportsTableRow',
    components: {
        Badge,
        Icon,
    },
    props: {
        row: {
            type: Object,
            required: true,
        },
        breaks: {
            type: Array,
            required: true,
        },
        collapsed: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        getCellValue(breakIdx, contentId, contentAnswer, breakAnswer) {
            try {
                const key = [contentId, contentAnswer, breakAnswer].join('|').toLowerCase();
                return this.breaks[breakIdx]['cells'][key];
            } catch (error) {
                this.$log.info('no value found for break');
                return '';
            }
        },
    },
};
</script>

<style scoped lang="scss">
.rt {
    &__row {
        height: 40px;

        &--question {
            border-top: solid 1px #dfe1e9;
            font-weight: $font-weight-bold;
        }

        &--response {
            border-top: solid 1px #f3f4f7;
        }
    }

    &__cell {
        text-align: center;
        vertical-align: middle;

        &--message {
            background-color: #ffffff;
            text-align: left;
            width: 200px;
            left: 40px;
            position: absolute;
        }

        &--number {
            background-color: #ffffff;
            width: 40px;
            left: 0px;
        }

        &--type {
            background-color: #ffffff;
            width: 150px;
            left: 240px;
        }

        &--value {
            background-color: #ffffff;
            width: 100px;
        }

        &--sticky {
            position: sticky;
        }

        &:nth-child(2n + 4) {
            background-color: #fcfcfd;
        }
    }
}

.rt__row--response .rt__cell--message {
    padding-left: 10px;
}

.rt__row--question .rt__cell--value {
    > span {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    svg {
        margin-left: 10px;
        color: $table-accent-color;
    }
}
</style>
