<template>
    <div
        class="rt"
        :class="{ 'rt--can-scroll-right': canScrollRight, 'rt--can-scroll-left': canScrollLeft }"
        :style="{ '--left': `${left}px` }"
    >
        <FullPageLoadingSpinner :show="isLoading" />
        <div ref="container" class="rt__container">
            <table ref="table" class="rt__table">
                <THead
                    :breaks="breaks"
                    :collapsed="collapsed"
                    @addBreak="$emit('addBreak')"
                    @toggleTotalUsers="onToggleTotalUsers"
                />

                <TBody :rows="rows" :breaks="breaks" :collapsed="collapsed" />
            </table>
        </div>
    </div>
</template>

<script>
import FullPageLoadingSpinner from '@/components/ui/FullPageLoadingSpinner';
import THead from './ReportsTableHeader';
import TBody from './ReportsTableBody';

export default {
    name: 'ReportsTable',
    components: {
        FullPageLoadingSpinner,
        THead,
        TBody,
    },
    props: {
        rows: {
            type: Array,
            default: () => [],
        },
        breaks: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            containerWidth: null,
            tableWidth: null,
            scrollX: null,
            collapsed: true,
            left: 490,
        };
    },
    computed: {
        canScrollRight() {
            return this.tableWidth > this.containerWidth + this.scrollX;
        },
        canScrollLeft() {
            return this.scrollX > 0;
        },
    },
    watch: {
        breaks: {
            deep: true,
            handler() {
                this.setScrollData();
            },
        },
    },
    mounted() {
        this.setScrollData();
        this.$refs.container.addEventListener('scroll', this.handleScroll, false);
        window.addEventListener('resize', this.handleResize, false);
    },
    beforeDestroy() {
        this.$refs.container.removeEventListener('scroll', this.handleScroll, false);
        window.removeEventListener('resize', this.handleResize, false);
    },
    methods: {
        getWidths() {
            const containerWidth = this.$refs.container.clientWidth;
            const tableWidth = this.$refs.table.clientWidth;
            return { containerWidth, tableWidth };
        },
        setScrollData() {
            const { containerWidth, tableWidth } = this.getWidths();
            this.containerWidth = containerWidth;
            this.tableWidth = tableWidth;
            this.scrollX = this.$refs.container.scrollLeft;
        },
        handleScroll(event) {
            this.scrollX = event.target.scrollLeft;
        },
        handleResize() {
            this.setScrollData();
        },
        async onToggleTotalUsers() {
            this.collapsed = !this.collapsed;
            this.left = this.collapsed ? 490 : 690;
            await this.$nextTick();
            this.setScrollData();
        },
    },
};
</script>

<style scoped lang="scss">
.rt {
    width: 100%;
    position: relative;

    &.rt--can-scroll-right::after {
        opacity: 1;
    }

    &.rt--can-scroll-left::before {
        opacity: 1;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 15px;
        height: 100%;
        top: 0;
        opacity: 0;
        transition: opacity 100ms ease;
    }

    &::before {
        left: var(--left);
        background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, #eeeeee 100%);
    }

    &::after {
        right: 0;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #eeeeee 100%);
    }

    &__container {
        overflow-x: auto;
        width: 100%;
    }

    &__table {
        width: 100%;
        margin: auto;
        font-size: $font-size-xs;
        table-layout: fixed;
    }
}
</style>
