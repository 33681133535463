export enum ExportContentItemType {
    carousel = 'CAROUSEL',
    carousel1 = 'CAROUSEL',
    carousel2 = 'CAROUSEL',
    carousel3 = 'CAROUSEL',
    statement = 'STATEMENT',
    quick = 'QUICK REPLY',
    pick1 = 'BUTTONS',
    input = 'USER INPUT',
    date_picker = 'DATE PICKER',
}
