<template>
    <div class="page-layout-container">
        <div class="page-layout-main">
            <FullPageLoadingSpinner :show="pageIsLoading" />

            <template v-if="!pageIsLoading">
                <PageHeading :breadcrumbs="breadcrumbs" title="View report" />

                <div class="sub-header">
                    <div>
                        <h2 class="sub-header__title">{{ convo.name }}</h2>
                        <div class="sub-header__datestamp">
                            Created {{ convo.createdDate }} | Last Modified
                            {{ convo.modifiedDate }} at
                            {{ convo.modifiedTime }}
                        </div>
                    </div>

                    <div class="sub-header__toolbar">
                        <Toggle
                            id="channel-status-toggle"
                            label="Total Users"
                            :value="isTotalCount"
                            @change="onToggle"
                        />

                        <Menu>
                            <template v-slot:button="{ toggle }">
                                <Button
                                    has-border
                                    class="sub-header__button"
                                    size="small"
                                    @click="toggle"
                                >
                                    Download Report
                                    <Icon name="arrow-down" :size="9" />
                                </Button>
                            </template>

                            <DropDownItem icon="download-report" @click="downloadReport">
                                Download Report
                            </DropDownItem>

                            <DropDownItem
                                icon="download-breaks"
                                :disabled="pageIsLoading || reportBreaks.length === 0"
                                @click="downloadReportWithBreaks"
                            >
                                Download Report With Breaks
                            </DropDownItem>

                            <DropDownItem
                                icon="download-data"
                                :disabled="processingReport"
                                @click="generateRawData"
                            >
                                {{ rawReportContent }}
                            </DropDownItem>
                        </Menu>
                    </div>
                </div>

                <ReportsTable
                    :rows="reportsData"
                    :breaks="reportBreaks"
                    :is-loading="reportBreakLoading"
                    @addBreak="onAddBreak"
                />
            </template>
        </div>

        <ContentSelector
            v-if="showContentSelector"
            :value="contentSelector"
            :fixed-channel="true"
            :fixed-convo="true"
            title="Table Break"
            validation-content-type="message"
            @input="onBreakSelected"
            @close="showContentSelector = false"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import FullPageLoadingSpinner from '../components/ui/FullPageLoadingSpinner';
import convoTypes from '../json/convoTypes';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import { ExportContentItemType } from '@/store/enums/exportContentItemType.enum';
import { format, parseISO } from 'date-fns';
import { downloadCSV } from '../utils/downloadCsv';
import { ReportClient } from '../api/report';
import Toggle from '@/components/forms/Toggle';
import FileSaver from 'file-saver';
import * as io from 'socket.io-client';
import { getFinalReportData, getFinalReportBreakData } from '@/store/services/reportService';
import ReportsTable from '@/components/ReportsTable/ReportsTable';
import PageHeading from '@/components/ui/PageHeading';
import Menu from '@/components/ui/Menu';
import DropDownItem from '@/components/ui/DropDown/DropDownItem';
import ContentSelector from '@/components/ContentSelector/ContentSelector';
import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';
import { getLogicJumpData } from '@/store/services/logicJumpService';

let socket;

const BREAK_CONTENT_CHUNK_SIZE = 5;

export default {
    name: 'Reports',
    components: {
        FullPageLoadingSpinner,
        ContentSelector,
        DropDownItem,
        ReportsTable,
        PageHeading,
        Button,
        Toggle,
        Menu,
        Icon,
    },
    data() {
        return {
            LoadingFlag,
            breadcrumbs: [],
            isTotalCount: false,
            reportsDataCount: [],
            reportsDataPercentage: [],
            reportsData: [],
            processingReport: false,
            rawReportContent: '',
            fileNameKey: '',
            rawReportData: {
                downloadReport: 'Download Raw Report',
                generateReport: 'Generate Raw Report',
                processReport: 'Processing Raw Report. Please wait...',
            },
            convo: {
                name: '',
                createdDate: '',
                modifiedDate: '',
                modifiedTime: '',
            },
            showContentSelector: false,
            contentSelector: undefined,
        };
    },
    computed: {
        ...mapGetters('channel', {
            channelDetails: 'channelDetails',
        }),
        ...mapGetters('convoDetails', {
            convoDetailsView: 'convoDetailsView',
        }),
        ...mapState('reports', {
            reports: 'reports',
            reportBreaks: 'reportBreaks',
            reportBreakLoading: 'reportBreakLoading',
        }),
        convoType() {
            const convoType = convoTypes.find((t) => t.name === this.convoDetailsView.convo.type);
            if (convoType) {
                return convoType.title;
            }
            return 'Convo';
        },
        pageIsLoading() {
            return (
                this.$wait.is(LoadingFlag.ReportGet) || !(this.convo.name && this.convo.name.length)
            );
        },
        hasNoData() {
            return !this.reports && this.reports.length === 0;
        },
    },
    watch: {
        isTotalCount(newValue) {
            if (newValue) {
                this.reportsData = this.reportsDataCount;
            } else {
                this.reportsData = this.reportsDataPercentage;
            }
        },
    },
    async created() {
        const convoId = this.$route.params.convoId;
        await this.setChannelDetails();
        await this.loadConvo({ id: convoId, channelCode: this.channelDetails.channel.code });
        await this.getReport({ convoId });
        this.setBreadcrumbs();
        this.setConvoTypesAndDetails();
        this.setReportContent();
        this.reportsData = this.reportsDataPercentage;
        this.setupWebSocket();
        this.fileNameKey = `${convoId}-${this.convo.name}`;
        const filename = localStorage.getItem(this.fileNameKey);
        const self = this;
        if (filename) {
            self.processingReport = true;
            socket.connect();
            socket.emit('report-request-raw', {
                convo: this.convoDetailsView.convo,
                filename,
                authorization: this.$store.getters['auth/accessToken'],
                source: 'sorkin',
            });
        }
        this.setRawReportButton();
    },
    async beforeDestroy() {
        this.convo = {};
        this.fileNameKey = '';
        this.resetConvoReport();
        this.clearReportBreaks();
    },
    methods: {
        ...mapActions('convoDetails', {
            loadConvo: 'loadConvo',
        }),
        ...mapActions('channel', {
            setChannelDetails: 'setChannelDetails',
        }),
        ...mapActions('reports', {
            getReport: 'getReport',
            getReportBreak: 'getReportBreak',
            resetConvoReport: 'resetConvoReport',
            clearReportBreaks: 'clearReportBreaks',
        }),
        ...mapActions('logicJump', {
            openLogicJump: 'open',
            closeLogicJump: 'close',
            getContentSelectorData: 'getData',
        }),
        async onAddBreak() {
            const result = await getLogicJumpData({
                targetChannel: '',
                targetConvo: this.$route.params.convoId,
                targetContentItem: 0,
            });
            this.contentSelector = result;
            this.showContentSelector = true;
        },
        onBreakSelected({ convo, message }) {
            const contentItems = this.getContentItemsAfterBreakContent(message.id);
            const contentItemChunks = this.createChunks(contentItems, BREAK_CONTENT_CHUNK_SIZE);
            const requestData = [];
            for (const chunk of contentItemChunks) {
                requestData.push({
                    convoId: convo.id,
                    contentIds: chunk,
                    breakContentId: message.id,
                });
            }
            this.getReportBreak(requestData);
        },
        getContentItemsAfterBreakContent(breakContentId) {
            const contentItems = [];
            if (breakContentId) {
                for (const item of this.reports) {
                    if (item.contentItemId && item.questionIndex) {
                        contentItems.push(item.contentItemId);
                    }
                }
            }
            return contentItems;
        },
        createChunks(contentItemArray, chunkSize) {
            const array = [...contentItemArray];
            const results = [];

            while (array.length) {
                results.push(array.splice(0, chunkSize));
            }

            return results;
        },
        setBreadcrumbs() {
            const breadcrumbs = [];
            breadcrumbs.push(new Breadcrumb('Channels', { name: 'all-channels' }));
            let convoLink;
            if (this.channelDetails.channel.code) {
                convoLink = {
                    name: 'convo-list',
                    params: { channelCode: this.channelDetails.channel.code },
                };
            }
            breadcrumbs.push(new Breadcrumb(this.channelDetails.channel.name, convoLink));
            breadcrumbs.push(
                new Breadcrumb(`${this.convoType} CONVO: ${this.convoDetailsView.convo.name}`, {
                    name: 'messages',
                    channelCode: this.channelDetails.channel.code,
                    convoId: this.convoDetailsView.convo.id,
                }),
            );
            breadcrumbs.push(new Breadcrumb('Report'));

            this.breadcrumbs = breadcrumbs;
        },
        setConvoTypesAndDetails() {
            const itemTypes = {};
            for (const elem in ExportContentItemType) {
                itemTypes[elem] = ExportContentItemType[elem];
            }
            for (const item of this.reports) {
                if (item.type) {
                    item.type = itemTypes[item.type];
                }
            }

            const convoDetails = this.convoDetailsView.convo;

            this.convo.createdDate = format(parseISO(convoDetails.createdAt), 'dd/MM/yyyy');
            this.convo.modifiedDate = format(parseISO(convoDetails.updatedAt), 'dd/MM/yyyy');
            this.convo.modifiedTime = format(parseISO(convoDetails.updatedAt), 'HH:mm a');
            this.convo.name = convoDetails.name;
        },
        setReportContent() {
            let reportsDataCount = JSON.parse(JSON.stringify(this.reports));
            reportsDataCount.forEach((row) => {
                row['value'] = row.count;
                return row;
            });
            this.reportsDataCount = reportsDataCount;

            let reportsDataPercent = JSON.parse(JSON.stringify(this.reports));

            reportsDataPercent.forEach((row) => {
                if (!row.type) {
                    for (let r in row.percent) {
                        row.percent[r] += '%';
                    }
                    row['value'] = row.percent;
                } else {
                    row['value'] = row.count;
                }
                return row;
            });
            this.reportsDataPercentage = reportsDataPercent;
        },
        onToggle() {
            this.isTotalCount = !this.isTotalCount;
        },
        setRawReportButton() {
            const filename = localStorage.getItem(this.fileNameKey);
            if (this.processingReport) {
                this.rawReportContent = this.rawReportData.processReport;
            } else {
                if (filename) {
                    this.rawReportContent = this.rawReportData.downloadReport;
                } else {
                    this.rawReportContent = this.rawReportData.generateReport;
                }
            }
        },
        downloadReport() {
            const reportData = getFinalReportData(this.reports);
            const filename = this.convo.name + '_aggregated';
            const title = `,Channel Name : ${this.channelDetails.channel.name} Convo Name : ${this.convo.name}`;
            downloadCSV(filename, title, reportData);
        },
        downloadReportWithBreaks() {
            const reportData = getFinalReportBreakData(this.reports, this.reportBreaks);
            const filename = this.convo.name + '_aggregated_with_breaks';
            const title = `,Channel Name : ${this.channelDetails.channel.name} Convo Name : ${this.convo.name}`;
            downloadCSV(filename, title, reportData);
        },
        setupWebSocket: function () {
            const self = this;
            socket = io(process.env.VUE_APP_API, {
                autoConnect: false,
                transports: ['websocket'],
            });
            socket.on('connect', function () {
                console.log('connected to socket');
            });
            socket.on('exception', function (data) {
                self.$log.error('error while getting report data', data);
                self.clearState();
            });
            socket.on('disconnect', function () {
                console.log('disconnected from socket');
            });

            socket.on('report-data-done', function () {
                const filename = localStorage.getItem(self.fileNameKey);
                if (filename) {
                    self.processingReport = false;
                    self.setRawReportButton();
                }
            });

            socket.on('report-data-error', function () {
                self.clearState();
                socket.disconnect();
            });

            socket.on('report-data-requested', function (data) {
                if (self.fileNameKey) {
                    localStorage.setItem(self.fileNameKey, data.filename);
                }
            });
        },
        generateRawData: async function () {
            const filename = localStorage.getItem(this.fileNameKey);
            if (!filename) {
                socket.connect();
                this.processingReport = true;
                this.setRawReportButton();
                socket.emit('report-request-raw', {
                    convo: this.convoDetailsView.convo,
                    authorization: this.$store.getters['auth/accessToken'],
                    source: 'sorkin',
                });
            } else {
                this.downloadRawReportS3(filename);
                this.clearState();
            }
        },
        downloadRawReportS3: function (filename) {
            const key = `reports/V2/${filename}/${filename}`;
            ReportClient.downloadReport(key)
                .then((response) => {
                    FileSaver.saveAs(response, `${filename}.csv`);
                })
                .catch((err) => {
                    this.$log.error('error while getting report', err);
                });
        },
        clearState: function () {
            if (localStorage.getItem(this.fileNameKey)) {
                localStorage.removeItem(this.fileNameKey);
            }
            this.processingReport = false;
            this.setRawReportButton();
        },
    },
};
</script>

<style lang="scss" scoped>
.reports-spinner {
    @include center();
    position: absolute;
}

.page-layout-main {
    font-family: $font-family-default;
}

.sub-header {
    margin-bottom: 24px;
    display: flex;

    &__title {
        margin-bottom: 10px;
        font-size: 20px;
        color: $title-text-color;
    }

    &__button {
        margin-left: 24px;

        .icon {
            display: inline;
            margin-left: 5px;
        }
    }

    &__datestamp {
        font-size: $font-size-xs;
        color: $alternate-text-color;
    }

    &__toolbar {
        margin-left: auto;
        display: flex;
        align-items: center;
    }
}
</style>
