<template>
    <tbody class="tr__body">
        <tr v-if="hasNoData">
            <td colspan="7" class="tr__row--no-data">{{ 'No data' }}</td>
        </tr>

        <ReportsTableRow
            v-for="(row, index) in rows"
            :key="`cr${index}`"
            :row="row"
            :breaks="breaks"
            :collapsed="collapsed"
        />
    </tbody>
</template>

<script>
import ReportsTableRow from './ReportsTableRow';

export default {
    name: 'ReportsTableBody',
    components: {
        ReportsTableRow,
    },
    props: {
        rows: {
            type: Array,
            required: true,
        },
        breaks: {
            type: Array,
            required: true,
        },
        collapsed: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasNoData() {
            return this.rows.length === 0;
        },
    },
};
</script>

<style scoped lang="scss">
.tr__body {
    background-color: $default-light-color;
}

.tr__row--no-data {
    height: 40px;
    text-align: center;
    vertical-align: middle;
}
</style>
